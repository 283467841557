import * as React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";

interface IImprintPageTemplateProps {
  content;
  description;
  title;
}

export const ImprintPageTemplate = (props: IImprintPageTemplateProps) => (
  <Layout deUrl="/de/impressum" enUrl="/en/imprint">
    <Helmet title={props.title}>
      <meta name="description" content={props.description} />
    </Helmet>
    <div dangerouslySetInnerHTML={{ __html: props.content }} />
  </Layout>
);

interface IImprintPageProps {
  data;
}

export default (props: IImprintPageProps) => {
  const { markdownRemark: post } = props.data;

  return (
    <ImprintPageTemplate
      description={post.frontmatter.description}
      title={post.frontmatter.title}
      content={post.frontmatter.content}
    />
  );
};

export const pageQuery = graphql`
  query ImprintPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        content
      }
    }
  }
`;
